import React, { useEffect } from 'react'
// import mask from "./img/Group 243.png";
import playstore from "./img/image1.png";
import apple from "./img/image2.png";
import AOS from 'aos';
import 'aos/dist/aos.css';


const Mask = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className='flex justify-between pl-20 m-auto pr-8 py-[5%] max-sm:p-0 max-sm: mt-[3.5rem] max-sm:ml-[12px] max-sm:mr-[12px]'>
            <div className=' justify-center w-full max-sm:hidden'>
                <div className='w-[25%]' data-aos="fade-right" data-aos-once='true' data-aos-duration="1000">
                    <div className='w-[100%] pt-5'>
                        {/* <img className=' h-full' src={mask}></img>0 */}
                    </div>
                </div>
                <div className='rounded-md bg-[#DEEBFF] flex justify-center place-content-center gap-y-5 w-[95%] pl-[8%]  px-[3%] py-[5%]' data-aos="fade-left" data-aos-once='true' data-aos-duration="1000">
                    <div className='w-full '>
                        <h1 className='text-[25px] font-bold text-[#002655]'>Download our app today.</h1>
                        <p className='text-sm mt-2 text-[#424141] w-[60%]'>
                            Download the App and build your land with ease. Develop and track updates and build your dream home.
                        </p>
                    </div>
                    <div className='flex mt-2 w-[100%]'>
                        <div className='w-[30%]'>
                            <div className='w-[90%]'>
                                <img src={playstore} alt=''></img>
                            </div>
                        </div>
                        <div className='w-[30%]'>
                            <div className='w-[90%]'>
                                <img src={apple} alt=''></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='md:hidden bg-[#DEEBFF]' data-aos="fade-up" data-aos-once='true' data-aos-duration="1000">
                <div className='w-full rounded-md bg-[#DEEBFF] grid place-content-center gap-y-5 pl-[8%]  px-[3%] py-[5%]'>
                    <div className='w-full '>
                        <h1 className='text-[25px] font-bold text-[#002655]'>Download our app Today</h1>
                        <p className='text-sm mt-2 text-[#797474] w-[80%]'>
                            Download the App and build your land with ease develop and track updates and build your dream home.
                        </p>
                    </div>
                    <div className='flex mt-2 w-[100%]'>
                        <div className='w-[30%]'>
                            <div className='w-[90%]'>
                                <img src={playstore} alt=''></img>
                            </div>
                        </div>
                        <div className='w-[30%]'>
                            <div className='w-[90%]'>
                                <img src={apple} alt=''></img>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='w-full'>
                    <div className='w-full pt-5'>
                        {/* <img className='block mx-auto w-[78%]' src={mask}></img> */}
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Mask