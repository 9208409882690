import React, { useEffect } from 'react'
import construct from "./img/Group 1.png";
import construct2 from "./img/construct2.png";
import vec from "./img/icons8-mission-100 (1) 1 (1).svg";
import vec1 from "./img/Vector (1).svg";
import vec2 from "./img/icons8-night-vision-64 1 (1).svg";
import vec12 from "./img/image.png";
import vec13 from "./img/Michael.png";
import vec14 from "./img/Dare copy.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
import Mask from './Mask';


const AboutUS = () => {
    useEffect(() => {
        AOS.init();
    }, [])
    return (
        <div className='w-full' >
           
            <div className='pl-20 pr-8 flex justify-center items-center py-[20%] h-[100vh] max-sm:px-5 max-sm:flex max-sm:flex-col max-sm:py-[20%]' id='background'>
                <div className='text-center pt-9 w-[100%]'>
                    <h1 className=' font-bold text-white text-[52px] max-sm:text-[32px] max-sm:leading-none max-sm:mt-4'>
                        <span className=''>About</span> Us
                    </h1>
                    {/* <p className=' leading-relaxed text-[15px] max-sm:hidden mt-4' id='inter'>
                        We are a real estate investment company committed to bridging home ownership gap in Nigeria.<br></br> We are keen on helping Nigerians bring their dream home to reality, stress-free, fast and trusted.
                    </p>
                    <p className=' leading-relaxed text-[15px] md:hidden mt-4' id='inter'>
                        We are a real estate investment company <br></br>
                        committed to bridging home <br></br>
                        ownership gap in Nigeria.<br></br>We are keen on helping Nigerians<br></br>
                        bring their dream<br></br>
                        home to reality, stress-free,<br></br>
                        fast and trusted.
                    </p> */}
                </div>
                {/* <div className='w-full flex justify-center pt-[5%] pr-8 max-sm:px-5'>
                    <div className='w-[90%] max-sm:w-full'>
                        <img src={apic} alt=''></img>
                    </div>
                </div> */}
            </div>
            {/* part1 */}
            <div className='pl-20 pr-8 relative w-full flex justify-end max-sm:flex max-sm:flex-col max-sm:mt-8 max-sm:px-5'>
                <div className='w-[50%] max-sm:w-full  absolute top-[-50%] left-[5rem] max-sm:left-0 max-sm:relative'>
                    <div className='w-[80%] max-sm:w-full'>
                        <img className='w-[100%]' src={construct2} alt=''></img>
                    </div>
                </div>
                <div className='w-[42%] max-sm:w-full pt-7 max-sm:mt-[5%]'>
                    <h1 className='leading-none font-bold text-[52px] max-sm:text-[32px]'>
                        <span className='text-[#6F5DE7]'>ABOUT US</span>
                    </h1>
                    <p className=' leading-relaxed text-[16px] mt-4 max-sm:text-[15px] w-[60%] font-bold flex flex-col gap-y-3 max-sm:w-full'>
                        We are a real estate investment company committed to bridging home ownership gap in Nigeria.
                        <span>We are keen on helping Nigerians bring their dream home to reality, stress-free, fast and trusted.</span>

                    </p>
                </div>
            </div>
            {/* part2 */}
            <div className='flex justify-between pl-20 pr-8 items-center max-sm:flex max-sm:flex-col max-sm:px-5 max-sm:mt-[7%] max-sm:py-[10%] max-sm:justify-normal ' id='backgroundThree'>
                <div className='flex max-sm:gap-x-3 '>
                    <div>
                        <img src={vec2} alt="" />
                    </div>
                    <div>
                        <h1 className='mt-4 text-[#6F5DE7] font-bold text-[52px] max-sm:text-[32px] max-sm:leading-none max-sm:mt-0'>
                            OUR <span className='text-[#6F5DE7]'>VISION</span>
                        </h1>
                        <p className='leading-relaxed text-[16px] mt-4 max-sm:text-[15px] w-[60%] max-sm:w-full max-sm:mt-2 font-bold flex flex-col max-sm:font-medium' id='inter'>
                            Our Vision is to be the leading provider of home ownership and mortgage penetration through technology
                        </p>
                    </div>

                    {/* <p className=' leading-relaxed text-[15px] md:hidden mt-4 w-[40%]' id='inter'>
                        Our mission is to make home ownership accessible to all by providing flexible financing options, that allow you to build your dream home without the immediate financial stress.
                    </p> */}
                </div>
                <div className=' w-[45%] mt-4 flex justify-around max-sm:w-full max-sm:mt-[10%] max-sm:justify-start'>
                    <div className='w-[80%] max-sm:w-full'>
                        <img src={construct} alt=''></img>
                    </div>
                </div>
                {/* <div className='w-full flex justify-center mt-8 max-sm:w-full'>
                    <a href='' className='flex w-[10%] justify-between max-sm:w-[38%]'><p className='text-[#6F5DE7] font-extrabold'>Start Building</p><BsArrowUpRight color='#6F5DE7' /></a>
                </div> */}
            </div>
            {/* part3 */}
            <div className='flex justify-between pr-8 items-center gap-x-4 max-sm:px-5 max-sm:gap-x-2 max-sm:mt-[6%]'>
                <div className='flex items-start justify-end w-[50%] pr-[6%] pt-[2%] h-[47vh] rounded-lg bg-[#002655] max-sm:px-6 max-sm:pt-[4%]'>
                    <img src={vec} className='max-sm:w-[50%]' alt="" />
                    <h2 className='text-white text-[52px] font-bold mt-[4%] max-sm:text-[35px] max-sm:mt-[6%]'>
                        Our
                    </h2>
                </div>
                <div className='w-[50%] pr-[6%] pl-[8%] max-sm:p-[0]  pt-[3%] h-[47vh] justify-center max-sm:pt-[4%]'>
                    <h2 className='text-[#6F5DE7] text-[52px] font-bold max-sm:text-[35px] max-sm:mt-[6%]'>
                        Mission
                    </h2>
                    <p className=' leading-relaxed text-[16px] mt-2 max-sm:text-[15px] w-[79%] max-sm:w-full font-bold flex flex-col gap-y-3'>
                        Our mission is to make home ownership accessible to all by providing flexible financing options, that allow you to build your dream home without the immediate financial stress.
                    </p>
                </div>
            </div>
            <div className='flex items-center mt-[3%] pl-20 gap-x-7 max-sm:px-5 max-sm:py-8'>
                <div>
                    <img src={vec1} alt="" />
                </div>
                <div className='flex flex-col gap-y-2 pt-6'>
                    <h3 className='text-[#002655] text-[52px] font-bold max-sm:text-[25px]'>Our Leadership Team </h3>
                    <p className='text-[16px] max-sm:text-[14px]'>
                        Meet Our Unique Team
                    </p>
                </div>
            </div>
            {/* admin */}
            <div className='pl-20 pr-8 flex justify-center gap-[2.5rem] items-center mt-[6%] max-sm:px-5 max-sm:flex-col max-sm:gap-y-4'>
                <div>
                    <div className='max-w-[400px] max-sm:w-[100%]'>
                        <img className='w-full rounded-lg' src={vec12} alt="" />
                    </div>
                    <div className='flex flex-col gap-y-1 mt-2'>
                        <h2 className='text-[#002655] max-w-[450px] text-[30px] font-bold'>Babatope Adewoye</h2>
                        <p className='text-[16px]'>MD, Buildnowpaylater</p>
                    </div>
                </div>
                <div>
                    <div className='max-w-[410px] max-sm:w-full'>
                        <img src={vec13} alt="" className='w-[100%] rounded-lg' />
                    </div>
                    <div className='flex flex-col gap-y-1 mt-2'>
                        <h2 className='text-[#002655] text-[30px] font-bold'>Micheal Adetutu</h2>
                        <p className='text-[16px]'>Chief Risk Officer</p>
                    </div>
                </div>
                <div>
                    <div className='max-w-[290px] max-h-[550px] max-sm:w-full max-sm: max-w-[100%] max-sm: max-h-[100%]'>
                        <img className='w-[100%] rounded-lg' src={vec14} alt="" />
                    </div>
                    <div className='flex flex-col gap-y-1 mt-2'>
                        <h2 className='text-[#002655] text-[30px] font-bold'>Dare Makinde</h2>
                        <p className='text-[16px]'>Chief Commercial Officer</p>
                    </div>
                </div>
            </div>
            {/* download */}
            <Mask />
        </div>
    )
}
export default AboutUS