import React from "react";
import logo from "./img/Group.svg";
import frame from "./img/Frame 167.svg";
import playstore from "./img/image1.png";
import apple from "./img/image2.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { FaTwitter, FaLinkedinIn, FaInstagram, FaFacebook } from "react-icons/fa";

const Footers = () => {
  return (
    <div
      className="flex pl-20 pr-8 pt-16 pb-14 text-sm max-sm:px-5 max-sm:flex max-sm:flex-col max-sm:pb-[20%]"
      id="roboto">
      <div className="mr-4">
        <img src={frame} alt="" />
      </div>
      <div className=" w-[35%] max-sm:w-full max-sm:mt-[1rem]">
        <div>
          <img src={logo} alt=""></img>
        </div>
        <div className="pt-[10%]">
          <p>Download our App today.</p>
        </div>
        <div className="flex flex-col justify-between gap-y-3 mt-[10%] w-[90%]">
          <div className="w-[50%]">
            <div className="w-[90%]">
              <img src={playstore} alt=""></img>
            </div>
          </div>
          <div className="w-[50%]">
            <div className="w-[90%]">
              <img src={apple} alt=""></img>
            </div>
          </div>
        </div>
      </div>
      {/* mobile part page...service  */}
      <div className="md:hidden flex w-full mt-[5%]">
        <div className="w-[50%] leading-[70px]">
          <div>
            <h1 className="font-bold text-lg">Company</h1>
          </div>
          <div className="pt-[10%]">
            <ul>
              <a href="/">
                <li>Home</li>
              </a>
              <a href="/">
                <li>About</li>
              </a>
              <a href="/contactus">
                <li>Contact Us</li>
              </a>
              <a href="/">
                <li>Terms of Service</li>
              </a>
            </ul>
          </div>
        </div>
        <div className="w-[50%] leading-[70px]">
          <div>
            <h1 className="font-bold text-lg">Service</h1>
          </div>
          <div className="pt-[10%]">
            <ul>
              <a href="/">
                <li>Build Now</li>
              </a>
              <a href="/">
                <li> Build Later</li>
              </a>
              {/* <a href='#'><.li>Ui/UX Design</li></a> */}
            </ul>
          </div>
        </div>
      </div>
      {/* ends here  */}
      <div className="w-[20%] leading-[33px] max-sm:hidden">
        <div>
          <h1 className="font-bold text-lg">Company</h1>
        </div>
        <div className="pt-[10%]">
          <ul>
            <li>
              <a
                className="w-fit-content"
                href="/">
                Home
              </a>
            </li>
            <li>
              <a className="w-fit-content" href="/about">
                About
              </a>
            </li>
            <li>
              <a className="w-fit-content" href="/contactus">
                Contact Us
              </a>
            </li>
            <li>
              <a className="w-fit-content" href="/">
                Terms of Service
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="w-[20%] leading-[33px] max-sm:hidden">
        <div>
          <h1 className="font-bold text-lg">Service</h1>
        </div>
        <div className="pt-[10%]">
          <ul>
            <li>
              <a href="/">Build Now</a>
            </li>
            <li>
              <a href="/">Build Later</a>
            </li>
            {/* <a href='#'><.li>Ui/UX Design</li></a> */}
          </ul>
        </div>
      </div>
      <div className="w-[40%] justify-between max-sm:w-full max-sm:mt-5">
        <div>
          <h1 className="font-bold text-lg">Contact</h1>
        </div>
        <div className="pt-[6%] leading-[33px] max-sm:leading-[70px]">
          <ul>
            {/* <a href="/"> */}
            <li className="flex items-center w-[100%]">
              <BsFillTelephoneFill size={20} />
              <span className="ml-2">+2349169830758</span>
            </li>
            {/* </a> */}
            {/* <a href="/"> */}
            <li className="flex items-center w-[100%]">
              <MdEmail size={20} />
              <span className="ml-2">support@buildnowpaylater.co</span>{" "}
            </li>
            {/* </a> */}
            {/* <a href="/"> */}
            <li className="flex items-start text-start leading-normal">
              <IoLocationSharp size={20} />{" "}
              <span className="ml-2 mt-[1%]">
                No 5C, Aderibigbe Shitta Street Maryland, Lagos .
              </span>{" "}
            </li>
            {/* </a> */}
          </ul>
        </div>
      </div>
      <div className="w-[20%] max-sm:w-full max-sm:mt-[30%] ">
        <div>
          <h1 className="font-bold text-lg">Social Media</h1>
        </div>
        <div className="flex flex-col gap-y-3 justify-between w-[90%] mt-[15%] max-sm:w-[50%] max-sm:inline-flex max-sm:gap-x-4">
          {/* <a href=''><FaFacebookF size={23} /></a> */}
          <a href="https://x.com/buildnowpaylata?t=7yCV7g-g_HWxYgdSbEEU6g&s=09">
            <FaTwitter size={23} />
          </a>
          <a href="https://www.linkedin.com/company/buildnowpaylater/">
            <FaLinkedinIn size={23} />
          </a>
          <a href="https://instagram.com/_bnpl_?igshid=NzZlODBkYWE4Ng==">
            <FaInstagram size={23} />
          </a>
          <a href="https://www.facebook.com/profile.php?id=61555114243641&mibextid=ZbWKwL">
            <FaFacebook size={23} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footers;
