import React, { useEffect } from "react";
import logo from "./img/Group.svg";
import logo1 from "./img/BNPL LOGO WHITE 1.svg";
import { CgMenuRight } from "react-icons/cg";
import { GrClose } from "react-icons/gr";
import mlogo from "./img/bbnplmobileLogo.svg";
import { useState } from "react";
import { Link } from "react-router-dom";

const NavBar = () => {
  const [open, SetOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  const toogleMenu = () => {
    SetOpen(!open);
  };

  useEffect(() => {
    const handleScroll = () => {
      const offset = 20;
      const pastOffset = window.scrollY > offset;
      setIsSticky(pastOffset);
      console.log(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);


  
  return (
    <div
      className={`${
        isSticky
          ? "w-full flex z-30 pl-20 pr-5 pb-4 py-5 flex-row mt-2 sticky top-0 bg-white max-sm:w-full max-sm:pt-2 max-sm:px-5 max-sm:justify-between max-sm:h-[12vh] max-sm:sticky max-sm:top-0 ease-in duration-100"
          : "w-full flex z-30 pl-20 pr-5 pb-4 py-5 flex-row mt-2 absolute top-0 bg-transparent max-sm:w-full max-sm:pt-2 max-sm:px-5 max-sm:justify-between max-sm:h-[12vh] max-sm:sticky max-sm:top-0 ease-in duration-100"
      }`}
      id="inter">
      <div className="m-auto max-w-[1600px] w-[100%] flex align-center justify-space-between">
        {" "}
        <div className="w-[100%]  flex align-center ">
          <div className="w-[40%] flex items-center">
            <div className="w-[51%] flex items-start">
              <a target="_self" href="/">
                <img
                  className=" max-sm:hidden cursor-pointer"
                  src={`${isSticky ? logo : logo1}`}
                  alt=""></img>
              </a>
              <a target="_self" href="/">
                <img className="md:hidden" src={mlogo} alt=""></img>
              </a>
            </div>
          </div>
          <div className=" w-full h-auto flex items-center justify-center max-sm:hidden">
            <ul className="w-[50%] flex justify-evenly text-center" id="family">
              <Link to="/" exact>
                <li
                  className={`${
                    isSticky
                      ? "text-[#23262B] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                      : " text-[#fff] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                  }`}>
                  Home
                </li>
              </Link>
              <Link to="/about">
                <li
                  className={`${
                    isSticky
                      ? "text-[#23262B] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7]"
                      : " text-[#fff] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                  }`}>
                  About
                </li>
              </Link>
              <Link to="/">
                <li
                  className={`${
                    isSticky
                      ? "text-[#23262B] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                      : " text-[#fff] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7]ease-in-out duration-150"
                  }`}>
                  FAQ
                </li>
              </Link>
              <Link to="/contactus">
                <li
                  className={`${
                    isSticky
                      ? "text-[#23262B] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                      : " text-[#fff] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                  }`}>
                  Contact
                </li>
              </Link>
            </ul>
          </div>
        </div>
        <div className="w-[300px] flex gap-[2.5rem] items-center max-sm:w-[50%] max-sm:justify-between">
          <div className="max-md:hidden">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://account.buildnowpaylater.co/create-account">
              <p
                className={`${
                  isSticky
                    ? "text-[#23262B] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                    : " text-[#fff] font-semibold text-[14px] cursor-pointer hover:border-b-2 hover:border-[#6F5DE7] ease-in-out duration-150"
                }`}>
                Register
              </p>
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://account.buildnowpaylater.co/">
              <button className=' relative hover:shadow-[0_5px_13px_-11px_rgba(0,0,0,0.3)] hover:shadow-[#6F5DE7] border border-transparent hover:text-black rounded-md bg-[#6F5DE7] text-[14px] text-center px-5 py-2 text-[#ffffff] cursor-pointer before:rounded-md before:content-[""] before:absolute before:w-[100%] before:h-[100%] before:bg-gradient-to-r before:from-white before:via-white before:to-white before:top-0 before:left-0 before:scale-x-0 before:transition-transform before:ease-in-out before:origin-right before:duration-300 hover:before:scale-x-100 hover:before:origin-left hover:border-[#6F5DE7]'>
                <span className="z-10 relative">Login</span>
              </button>
            </a>
          </div>
          <div className="md:hidden ml-2 mt-2">
            <button className="cursor-pointer" onClick={toogleMenu}>
              {open ? (
                <GrClose size={28} color="#00317A" />
              ) : (
                <CgMenuRight size={39} color="#00317A" />
              )}
            </button>
            {open && (
              <div className=" absolute left-0 justify-center w-full h-[50vh] bg-white px-9">
                <ul
                  className="w-full flex flex-col justify-evenly text-center h-[50%]"
                  id="family">
                  <Link onClick={()=>SetOpen(false)} to="/">
                    <li className=" text-[#23262B] font-semibold text-[14px] cursor-pointer ease-in-out duration-150">
                      Home
                    </li>
                  </Link>
                  <Link onClick={()=>SetOpen(false)} to="/about">
                    <li className=" text-[#23262B] font-semibold text-[14px] cursor-pointer ease-in-out duration-150">
                      About
                    </li>
                  </Link>
                  <Link onClick={()=>SetOpen(false)} to="/contactus">
                    <li className=" text-[#23262B] font-semibold text-[14px] cursor-pointer ease-in-out duration-150">
                      Contact
                    </li>
                  </Link>
                </ul>
                <ul
                  className="w-full flex flex-col justify-evenly text-center"
                  id="family">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://account.buildnowpaylater.co/">
                    <button className=" border-2 border-transparent rounded-md bg-[#6F5DE7] h-[50px] text-[14px] text-center w-[50%] text-[#ffffff] cursor-pointer">
                      Login
                    </button>
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://account.buildnowpaylater.co/create-account">
                    <p className=" text-[#23262B] font-semibold text-[14px] mt-[1rem] cursor-pointer ease-in-out duration-150">
                      Register
                    </p>
                  </a>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
