// import React, { useEffect } from 'react'
// import buildhome from "./img/mbuildhome.png"
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// const BuildHome = () => {
//   useEffect(() => {
//     AOS.init();
//   }, [])
//   return (
//     <div className='max-sm:hidden' data-aos="fade-up" data-aos-once='true' >
//       <div className='flex justify-between pt-[5%] pl-20 pr-8 mt-5 max-sm:pl-5' id='backgroundTwo'>
//         <div className='w-[15%] max-sm:w-[60%] h-[27vh] max-sm:h-[23vh] px-3 flex flex-col gap-y-3 max-sm:gap-x-3 bg-white rounded-md shadow-md'>
//           <h1 className='font-bold text-[20px] text-[#00317A]'>
//             ENOUGH OF DREAMING !!!
//           </h1>
//           <h2 className=' leading-relaxed text-[20px] font-bold' id='inter'>
//             Build your
//             Dream home
//             today!
//           </h2>
//         </div>
//         <div className='gr px-3 py-3 max-sm:h-[25vh]'>
//           <p className='w-[50%] max-sm:text-[14px] max-sm:w-[90%]'>
//             We understand how hard it is to build a home in Nigeria, this is why we provide you with the best investment home ownership deal to bring your dream home to reality.
//           </p>
//         </div>
//       </div>
//       {/* mobile view */}
//       {/* <div className='px-5 mt-[10%] md:hidden'>
//         <div className='w-full flex flex-col max-sm:gap-y-3'>
//           <h1 className='font-bold text-[20px]'>
//             ENOUGH OF DREAMING
//           </h1>
//           <p className='text-[#797474] w-[50%] max-sm:w-full leading-relaxed text-[14px]' id='inter'>

//             We understand how hard it is to build a home in Nigeria, this is why we provide you with the best investment home ownership deal to bring your dream home to reality.
//           </p>
//         </div>
//       </div>
//       <div className='w-full md:hidden mt-4'>
//         <img className='w-full' src={buildhome} alt=''></img>
//       </div> */}
//     </div>
//   )
// }

// export default BuildHome

import React, { useEffect } from "react";
import emoji from "./img/01- 3B BUNGALOW View2.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
const BuildHome = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      className="flex pl-20  gap-x-6 items-center justify-between max-sm:flex max-sm:flex-col max-sm:px-5 max-sm:w-full max-sm:items-start max-sm:mt-[10%]"
      data-aos="fade-down"
      data-aos-once="true">
      {/* <div className='max-sm:mt-8 flex justify-center w-[70%] max-sm:w-full'>
                <p className='max-sm:text-sm text-[#797474] w-auto text-[15px] w-[40%] text-center' id='inter'>
                    Our partner/parent company, Continental and Civil Construction have successfully completed (number) building projects across (number) states in Nigeria
                </p>
            </div> */}
      <div className="w-[50%] max-sm:w-full">
        <h1 className="text-[#002655] font-bold text-[35px] max-sm:text-[26px] max-sm:leading-none max-sm:mt-8 max-sm:w-[90%] max-sm: mb-[1.2rem]">
          Enough of the Dreaming!
        </h1>
        <p className="w-[100%] max-sm: m-[10px 10px] max-sm:leading-[32px]">
          Understanding the intricate challenges of building homes in Nigeria, BNPL
          seeks to address practical aspects and fulfill aspirational dreams of
          aspiring landlords across Nigeria by facilitating the seamless transition
          from vision to reality.
        </p>
      </div>
      <div className="w-[60%] max-sm:w-[100%] max-sm:mt-[1rem]">
        <img src={emoji} alt=""></img>
      </div>
    </div>
  );
};

export default BuildHome;

// border-2 border-solid border-red-500
