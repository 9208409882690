import React, { useEffect } from "react";
import emoji from "./img/guilherme-cunha-4zwozQxDbD4-unsplash.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
const Emoji = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div
      className="flex  border-2   gap-x-6 items-center justify-between max-sm:flex max-sm:flex-col max-sm:px-5 max-sm:w-full max-sm:items-start max-sm:mt-[10%]"
      data-aos="fade-down"
      data-aos-once="true">
      <div className="w-[50%] max-sm:w-[100%]">
        <img src={emoji} alt=""></img>
      </div>
      {/* <div className='max-sm:mt-8 flex justify-center w-[70%] max-sm:w-full'>
                <p className='max-sm:text-sm text-[#797474] w-auto text-[15px] w-[40%] text-center' id='inter'>
                    Our partner/parent company, Continental and Civil Construction have successfully completed (number) building projects across (number) states in Nigeria
                </p>
            </div> */}
      <div className="w-[50%] pl-10 pr-10 max-sm:w-full max-sm:p-[0]">
        <h1 className="text-[#002655] font-bold text-[35px] max-sm:text-[26px] max-sm:leading-none max-sm:mt-8 max-sm:w-[100%] max-sm: mb-[1rem]">
          Trusted Construction Partners
        </h1>
        <p className="w-[100%] max-sm:w-[90%] max-sm: leading-[30px]">
          Our partner/parent company, Continental and Civil Construction has
          successfully completed several building projects across several states in
          Nigeria
        </p>
      </div>
    </div>
  );
};

export default Emoji;
