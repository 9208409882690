import React from "react";
// import home from "./img/Group 241.svg";
// import home from "../components/img/03- DETACHED View2.jpg"
// import Shelter from "./img/Shelter.svg";
// import Rentease from "./img/Rentease.svg";
// import Remita from "./img/remita.svg";
// import Budpay from "./img/Budpay.svg";
import {
  //  BsArrowUpRight, 
   BsArrowRight } from "react-icons/bs";

const Home = () => {
  return (
    <div className="relative">
      <div id="overlay"></div>
      <div
        className=" w-full pl-20 pr-8 pt-[6%] pb-[11%] flex max-sm:px-5 max-sm:flex max-sm:flex-col max-sm:py-0"
        id="background">
        <div className="  w-[50%] pt-[10%] m-auto max-sm:w-full z-[9] text-center">
          <div className="flex flex-col max-sm:gap-y-5">
            <h1 className="text-white font-bold text-[52px] max-sm:text-[32px] w-[100%]">
              BUILD YOUR DREAM HOME
            </h1>
            <div className="border-t-2 w-[40%] m-auto"></div>
            <p className="text-white text-[15px] mt-[1rem] text-center leading-relaxed ">
              Bring your land, and together, we can turn it into your dream home.
              With a 30% down payment, you can have your dream home in just 6 months,
              and enjoy a repayment period of up to 3 years!
            </p>
          </div>
          <div className="mt-4 w-[45%] flex justify-center m-auto items-center max-sm:w-[90%] max-sm: mb-[2.5rem]">
            <a target="_blank" rel="noreferrer" href="/">
              <button className=" border-2 border-transparent rounded-md bg-[#6F5DE7] h-[50px] text-center w-[151px] text-[#ffffff] cursor-pointer gap-x-3 flex justify-center items-center">
                Get Started <BsArrowRight />
              </button>
            </a>
            {/* <buttton className='flex w-[37%] justify-between cursor-pointer'>learn more </buttton> */}
          </div>
        </div>
        {/* <div className='w-[50%] max-sm:mt-5 max-sm:w-full  z-[9]'>
                    <img src={home} alt=''></img>
                </div> */}
      </div>
      {/* <div className='bg-[#EFFBFF] flex items-center h-[30vh] p-5 justify-around pl-20 pr-8 max-sm:px-5 max-sm:h-auto max-sm:mt-[18%]'>
            <div className='w-[15%] max-sm:w-[18%]'>
                <img src={Shelter} alt=''></img>
            </div>
            <div className='w-[17%] max-sm:w-[20%]'>
                <img src={Rentease} alt=''></img>
            </div>
            <div className='w-[16%] max-sm:w-[16%]'>
                <img src={Remita} alt=''></img>
            </div>
            <div className='w-[19%] max-sm:w-[20%]'>
                <img src={Budpay} alt=''></img>
            </div>
        </div> */}
    </div>
  );
};

export default Home;
