import React, { useEffect } from "react";
// import { BsArrowUpRight } from "react-icons/bs";
// import vec from "./img/Traffic.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const StartBuilding = () => {
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <div data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
      <div
        className="flex flex-col justify-center items-center max-sm:flex max-sm:flex-col max-sm:px-3"
        id="backgroundThree">
        <div className="grid place-items-center">
          <h1 className="text-center font-bold text-[52px] max-sm:text-[32px] max-sm:leading-none">
            Why Buildnowpaylater ?
          </h1>
          <div className='pl-20 pr-8 mt-[5%] w-[90%] flex justify-around max-sm:flex max-sm:flex-col max-sm:px-5' data-aos="fade-up" data-aos-once='true' data-aos-duration="1000">
         
            <div className='w-[25%] flex flex-col gap-y-4 max-sm:w-full max-sm:flex max-sm:mt-[10%] max-sm:px-5 max-sm:justify-between'>
                <div className='flex flex-col gap-y-3'>
                   
                    <div classname='mt-4 max-sm:w-[50%]'>
                        <h1 className='font-bold text-lg '>
                        Financial Flexibility
                        </h1>
                        <p className='text-sm  max-sm:text-[12px] text-[#797474]' id='inter'>
                        BNPL provides customers with the flexibility to spread out the cost of building a home over a period of time.
                        </p>
                    </div>
                </div>
                <div className='max-sm:hidden flex flex-col gap-y-3'>
                    <div classname='mt-4 max-sm:w-[50%]'>
                        <h1 className='font-bold text-lg '>
                        Access to Homeownership:
                        </h1>
                        <p className='text-sm  max-sm:text-[12px] text-[#797474]' id='inter'>
                        BNPL options enable customers to commence the home building process without waiting to accumulate the entire construction cost upfront.
                        </p>
                    </div>
                </div>
            </div>
            <div className='w-[25%] flex flex-col gap-y-4 max-sm:w-full max-sm:flex max-sm:mt-[10%] max-sm:px-5 max-sm:justify-between'>
               
                <div className=' max-sm:hidden flex flex-col gap-y-3'>
                    <div classname='mt-4 max-sm:w-[50%]'>
                        <h1 className='font-bold text-lg '>
                        Interest-Free Periods:
                        </h1>
                        <p className='text-sm  max-sm:text-[12px] text-[#797474]' id='inter'>
                        We offer interest-free periods, allowing customers to pay off the balance without incurring additional costs if they adhere to the agreed-upon repayment schedule.
                        </p>
                    </div>
                </div>
                <div className=' max-sm:mt-0 flex flex-col gap-y-4'>
                    <div classname='mt-4 max-sm:w-[50%]'>
                        <h1 className='font-bold text-lg '>
                        Quick and Convenient
                        </h1>
                        <p className='text-sm  max-sm:text-[12px] text-[#797474]' id='inter'>
                        The application process for BNPL is often quicker and more straightforward compared to traditional mortgage applications, making it more convenient for customers.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
        {/* <div className=" w-[45%] mt-4 flex gap-x-4 justify-around max-sm:w-full max-sm:hidden">
          <button className="border border-black rounded-md py-3 bg-transparent w-[151px] max-sm:p-2 text-[#000000] text-sm text-center">
            Stress Free
          </button>
          <button className="border border-black rounded-md py-3 bg-transparent w-[200px] max-sm:p-2 text-[#000000] text-sm text-center">
            Flexible Payment
          </button>
          <button className="border border-black rounded-md w-[121px] bg-transparent text-[#000000] text-sm text-center py-3">
            Secure
          </button>
          <button className="border border-black rounded-md w-[121px] bg-transparent text-[#000000] text-sm text-center py-3">
            Trusted
          </button>
          <a
            href=""
            className="flex items-center justify-center gap-x-3 border border-black bg-[#00317A] rounded-md py-3 w-[230px] max-sm:p-2 text-[#000000] text-sm text-center">
            <p className="text-[#0084FE] font-extrabold">Start Building</p>
            <BsArrowUpRight color="#0084FE" />
          </a>
        </div> */}
        {/* <div className='w-full flex justify-center mt-8 max-sm:w-full'>
                </div> */}
      </div>
      {/* mobile view */}
      <div></div>
    </div>
  );
};

export default StartBuilding;
