import React, { useEffect } from "react";
import construct from "./img/bnplconstruction.jpeg";
import verified from "./img/verified.svg";
import buildingplan from "./img/buildplan.svg";
import uploadplan from "./img/uploadplan.svg";
import dreamhome from "./img/dreamhome.svg";
import AOS from "aos";
import "aos/dist/aos.css";

const Construction = () => {
   useEffect(() => {
      AOS.init();
   }, []);
   return (
      <div
         className="pl-20 pr-8 w-full flex justify-between max-sm:flex max-sm:flex-col max-sm:px-5"
         data-aos="fade-up"
         data-aos-once="true"
         data-aos-duration="1000">
         {/* <div className='w-[45%] max-sm:w-full rounded-lg relative' >
                <div className=' rounded-lg absolute bg-indigo-500 bg-opacity-40 top-[0] bottom-[0] h-[100%] w-[100%]' ></div>
                <img src={construct} className='rounded-md' alt=''></img>
            </div> */}
         <div className="w-[35%] max-sm:w-full rounded-md relative">
            <div className=" rounded-lg absolute bg-indigo-500 bg-opacity-30 top-[0] bottom-[0] h-[100%] w-[100%]"></div>
            <img src={construct} className="rounded-md  w-[100%]" alt=""></img>
         </div>
         <div className="w-[25%] flex flex-col gap-y-[5rem] max-sm:w-full max-sm:flex max-sm:mt-[10%] max-sm:px-5 max-sm:justify-between">
            <div className="flex flex-col gap-y-3">
               <div>
                  <img src={verified} alt=""></img>
               </div>
               <div classname="mt-4 max-sm:w-[50%]">
                  <h1 className="font-bold text-lg ">Get Verified</h1>
                  <p className="text-sm  max-sm:text-[12px] text-[#797474]" id="inter">
                     Register and verify your account by submitting your KYC and Work information
                  </p>
               </div>
            </div>
            <div className="md:hidden">
               <div>
                  <img src={uploadplan} alt=""></img>
               </div>
               <div classname="mt-4 max-sm:w-[50%] max-sm:ml-2">
                  <h1 className="font-bold text-lg">Select Preferred Service</h1>
                  <p className="text-sm  max-sm:text-[12px] text-[#797474]" id="inter">
                     Choose from one of our land development options. You can upload your own plan
                     or select from our numerous building plans
                  </p>
               </div>
            </div>
            <div className="max-sm:hidden flex flex-col gap-y-3">
               <div>
                  <img src={uploadplan} alt=""></img>
               </div>
               <div classname="mt-4 max-sm:w-[50%]">
                  <h1 className="font-bold text-lg ">Select Preferred Service</h1>
                  <p className="text-sm  max-sm:text-[12px] text-[#797474]" id="inter">
                     Choose from one of our land development options. You can upload your own plan
                     or select from our numerous building plans
                  </p>
               </div>
            </div>
         </div>
         <div className="w-[25%] flex flex-col gap-y-[5rem] max-sm:w-full max-sm:flex max-sm:mt-[10%] max-sm:px-5 max-sm:justify-between">
            <div className="mt-4 max-sm:mt-0 md:hidden">
               <div>
                  <img src={buildingplan} alt=""></img>
               </div>
               <div classname="mt-4 max-sm:w-[50%]">
                  <h1 className="font-bold text-lg ">Make Downpayment</h1>
                  <p className="text-sm  max-sm:text-[12px] text-[#797474]" id="inter">
                     Make a downpayment ranging from 30%-35% and we commence construction on your
                     land
                  </p>
               </div>
            </div>
            <div className=" max-sm:hidden flex flex-col gap-y-3">
               <div>
                  <img src={buildingplan} alt=""></img>
               </div>
               <div classname="mt-4 max-sm:w-[50%]">
                  <h1 className="font-bold text-lg ">Make Downpayment</h1>
                  <p className="text-sm  max-sm:text-[12px] text-[#797474]" id="inter">
                     Make a downpayment ranging from 30%-35% and we commence construction on your
                     land
                  </p>
               </div>
            </div>
            <div className=" max-sm:mt-0 flex flex-col gap-y-4">
               <div>
                  <img src={dreamhome} alt=""></img>
               </div>
               <div classname="mt-4 max-sm:w-[50%]">
                  <h1 className="font-bold text-lg ">Get Your Dream Home</h1>
                  <p className="text-sm  max-sm:text-[12px] text-[#797474]" id="inter">
                     Our Project team ensure your land is developed within 6 to 12 months, own your
                     dream home at a max of a year after your upfront payment
                  </p>
               </div>
            </div>
         </div>
      </div>
   );
};

export default Construction;
