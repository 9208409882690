import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import NavBar from "./components/NavBar";
import Footers from "./components/Footers";
import AboutUS from "./components/AboutUS";
import ContactUs from "./components/ContactUs";
import { ScrollToTop } from "./scrolltoTop";
function App() {
  
  return (
  <Router>
    <ScrollToTop/>
    <NavBar/>
      <Routes>
        <Route index path="/" element={<LandingPage/>}/>
        <Route path="/about" element={<AboutUS/>}></Route>
        <Route path="/contactus" element={<ContactUs/>}></Route>
      </Routes>
      <Footers/>
  </Router>
  );
}

export default App;
