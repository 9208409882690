import React from 'react'
import Home from './Home'
import BuildHome from './BuildHome'
import Emoji from './Emoji'
import StartBuilding from './StartBuilding'
import Construction from './Construction'
// import Testimonial from './Testimonial'
import Mask from './Mask'


const LandingPage = () => {
  return (
    <div className='max-w-[1600px] m-auto' >
      <Home/>
      <BuildHome/>
      <Emoji/>
      <StartBuilding/>
      {/* <FirstConstruction/> */}
      <Construction/>
      {/* <Testimonial/> */}
      <Mask/>
    </div>
  )
}

export default LandingPage