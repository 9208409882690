import React from "react";
import construct3 from "./img/construct3.png";
import Figmap from "./img/Figmap.png";
import bgbuild from "./img/aboutlastimg.png";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";

const ContactUs = () => {
  return (
    <div className="w-full pl-20 pr-8 max-sm:px-5 max-sm:flex max-sm:flex-col max-sm:py-0">
      <div className="text-center pt-9">
        <h1 className=" font-bold text-[52px] max-sm:text-[32px] max-sm:leading-none max-sm:mt-4">
          <span className="text-[#6F5DE7]">Contact</span> Us
        </h1>
      </div>
      <div className="w-full flex justify-between max-sm:grid">
        <div className="w-[50%] max-sm:w-full">
          <div className="w-full h-full">
            <img src={construct3} alt=""></img>
          </div>
        </div>
        <div className="w-[50%] py-20 max-sm:py-2 max-sm:w-full max-sm:px-5">
          <div>
            <h1 className=" font-bold text-[24px] max-sm:text-[29px] max-sm:leading-none max-sm:mt-4">
              Leave us a message<br></br>
              and get fast response
            </h1>
            {/* <p className='text-[#797474] leading-relaxed text-[12px] max-sm:text-[11px] mt-4'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br></br>
                        eiusmod tempor incididunt ut labore et dolore magna<br></br>
                        aliqua.Lorem ipsum dolor sit amet, consectetur adipiscing elit,<br></br>
                        sed do eiusmod tempor incididunt ut labore et dolore mag
                    </p> */}
          </div>
          <div className=" w-[55%] mt-2 max-sm:w-full max-sm:mt-5">
            <form>
              <div className="flex flex-col">
                <label className="text-[#797474] text-[14px]">Full Name</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded-md mt-2 outline-none text-[#797474]"></input>
              </div>
              <div className="flex flex-col mt-3">
                <label className="text-[#797474] text-[14px]">Email</label>
                <input
                  type="email"
                  className="w-full p-2 border rounded-md mt-2 outline-none text-[#797474]"></input>
              </div>
              <div className="flex flex-col mt-3">
                <label className="text-[#797474] text-[14px]">Message</label>
                <textarea
                  type="text"
                  className="w-full px-2 pt-2 pb-14 resize-none border rounded-md mt-2 outline-none text-[#797474] text-start"
                  rows="4"
                  placeholder="message goes here..."></textarea>
              </div>
              <input
                type="submit"
                className="w-full p-2 border rounded-md mt-2 outline-none text-white bg-[#6F5DE7]"
                value="Send"></input>
            </form>
          </div>
        </div>
      </div>
      <div className="flex justify-between w-full max-sm:flex max-sm:flex-col max-sm:mt-[5%]">
        <div>
          <h1 className=" font-bold text-[52px] max-sm:text-[32px] max-sm:leading-none max-sm:mt-4">
            <span className="text-[#6F5DE7]">Locate</span> Us
          </h1>
          {/* <p className='text-[#797474] leading-relaxed text-[12px] mt-2'>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do<br></br>
                    eiusmod tempor incididunt ut labore et dolore magna<br></br>
                    sed do eiusmod tempor incididunt ut labore et dolore mag
                </p> */}
          <div className="pt-[6%] leading-[33px] max-sm:leading-[70px]">
            <ul>
              <a href="/">
                <li className="flex items-center w-[100%] text-[12px]">
                  <BsFillTelephoneFill size={20} />
                  <span className="ml-2">+2349169830758</span>
                </li>
              </a>
              <a href="/">
                <li className="flex items-center w-[100%] text-[12px]">
                  <MdEmail size={20} />
                  <span className="ml-2">support@buildnowpaylater.co</span>{" "}
                </li>
              </a>
              <a href="/">
                <li className="flex items-start text-start leading-normal text-[12px]">
                  <IoLocationSharp size={20} />{" "}
                  <span className="ml-2 mt-[1%]">
                  No 5C, Aderibigbe Shitta Street Maryland, Lagos .
                  </span>{" "}
                </li>
              </a>
            </ul>
          </div>
        </div>
        <div className="w-[65%] max-sm:w-full max-sm:mt-4">
          <div>
            <img src={Figmap} alt=""></img>
          </div>
        </div>
      </div>
      <div className="w-full mt-10">
        <div className="pl-20 pr-8 max-sm:px-5">
          <img src={bgbuild} alt=""></img>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
